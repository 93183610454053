import React from "react";
import { Subscribe } from "unstated";

const withStore = containers => WrappedComponent => props => (
  <Subscribe to={containers}>
    {(...containers) => (
      <WrappedComponent {...mapContainers(containers)} {...props} />
    )}
  </Subscribe>
);

const mapContainers = containers =>
  containers.reduce((obj, item) => {
    obj[item.name.charAt(0).toLowerCase() + item.name.slice(1)] = item;
    return obj;
  }, {});

export default withStore;
