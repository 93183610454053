import { Container } from "unstated";
import apiBaseURL from "../../helpers/apiBaseURL";

class UserContainer extends Container {
  name = "user";
  state = { isAuthenticated: false, billing: {} };

  setUser(user) {
    return this.setState({ ...this.state, ...user, isAuthenticated: true });
  }

  getUser() {
    return fetch(`${apiBaseURL}/user`, {
      credentials: "include"
    });
  }

  updateUser() {
    return this.getUser()
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.error);
          });
        }

        return res.json();
      })
      .then((json) => this.setUser(json))
      .catch((error) => console.log(error));
  }

  fetchURLAndUpdateUser(queryURL, queryOptions) {
    return fetch(queryURL, { ...queryOptions, credentials: "include" })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            if (typeof json.error === "string") {
              throw new Error(json.error);
            }

            throw json.error;
          });
        }

        return this.updateUser();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  createStorage(id) {
    const queryURL = `${apiBaseURL}/storages`,
      queryOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id })
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  updateStorageAuth(id, options) {
    const queryURL = `${apiBaseURL}/storages/${id}`,
      queryOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: options
          ? JSON.stringify({
              basicAuthToken: `${options.username}:${options.password}`
            })
          : undefined
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  deleteStorage(id) {
    const queryURL = `${apiBaseURL}/storages/${id}`,
      queryOptions = {
        method: "DELETE"
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  setUserDetails(details) {
    const queryURL = `${apiBaseURL}/user/details`,
      queryOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(details)
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  logout() {
    window.location.href = `${apiBaseURL}/user/logout`;
  }

  createStripeCustomer() {
    return fetch(`${apiBaseURL}/user/create-stripe-customer`, {
      method: "POST",
      credentials: "include"
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.error);
          });
        }

        return res.json();
      })
      .then(() => this.setState({ isStripeCustomer: true }));
  }

  chooseSubscriptionPlan(dbIndex) {
    return this.setState({
      chosenSubscriptionPlan: dbIndex
    });
  }

  chooseSubscriptionInterval(interval) {
    return this.setState({ chosenSubscriptionInterval: interval });
  }

  createSubscription(planID) {
    const queryURL = `${apiBaseURL}/user/subscription`,
      queryOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ planID })
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  changeSubscriptionPlan(planID) {
    const queryURL = `${apiBaseURL}/user/subscription`,
      queryOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ planID })
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  cancelSubscription() {
    const queryURL = `${apiBaseURL}/user/subscription`,
      queryOptions = {
        method: "DELETE"
      };

    return this.fetchURLAndUpdateUser(queryURL, queryOptions);
  }

  changeCard(cardTokenID) {
    return fetch(`${apiBaseURL}/user/card`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ tokenID: cardTokenID })
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.error);
          });
        }

        return res.json();
      })
      .then((json) =>
        this.setState({
          billing: { ...this.state.billing, card: { last4: json.last4 } }
        })
      );
  }

  changeAddress(newAddress) {
    return fetch(`${apiBaseURL}/user/address`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ address: newAddress })
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.error);
          });
        }

        return res.json();
      })
      .then((json) =>
        this.setState({
          billing: { ...this.state.billing, address: json.address }
        })
      );
  }

  fetchAndSetBillingDetails() {
    return fetch(`${apiBaseURL}/user/billing`, {
      credentials: "include"
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((json) => {
            throw new Error(json.error);
          });
        }

        return res.json();
      })
      .then((json) => this.setState({ billing: json }));
  }
}

export default UserContainer;
