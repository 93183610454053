import React from "react";
import "./Login.css";
import { Redirect } from "react-router-dom";
import GoogleLogo from "../../assets/Google Logo.svg";
import withStore from "../../helpers/withStore";
import UserContainer from "../UserContainer/UserContainer";

function betaSignup(event) {
  event.preventDefault();
  const betaInviteCode = new URLSearchParams(window.location.search).get(
    "betaInviteCode"
  );

  window.location.href = `/auth/google?betaInviteCode=${betaInviteCode}`;
}

const Login = ({ user }) => {
  if (user.state.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center">
        <p className="text-greytext-light mb-1">
          To continue, sign in with Google
        </p>
        <button
          className="login-link flex items-center rounded-full border border-grey-light hover:border-accent text-greytext px-2 py-3 cursor-pointer w-full"
          aria-label="Login"
          onClick={betaSignup}
        >
          <span className="login-link-logo-container inline-flex justify-center items-center pl-4 pr-3 border-r border-grey-light">
            <img src={GoogleLogo} alt="Google" className="w-8" />
          </span>
          <span className="flex-1">Sign In</span>
        </button>
      </div>
      <p className="text-greytext-light mt-4 text-sm text-center px-4">
        By continuing, you agree to our{" "}
        <a href="https://steinhq.com/privacy-policy" className="animated-text-link">Privacy Policy</a> &{" "}
        <a href="https://steinhq.com/terms-of-service" className="animated-text-link">Terms of Service</a>
      </p>
    </div>
  );
};

export default withStore([UserContainer])(Login);
