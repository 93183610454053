import React, { memo } from "react";
import { Link } from "react-router-dom";
import useComponentVisible from "../../../../helpers/useComponentVisible";
import "./Avatar.css";

const dropDownItemClasses =
  "avatar-link pl-6 pr-10 py-3 block text-left w-full hover:bg-grey-lighter text-greytext modal-button-transition tracking-tight font-medium";

const Avatar = ({ userAvatar, logout }) => {
  const [ref, isOpen, setIsOpen] = useComponentVisible(false);

  return (
    <div className="inline-flex flex-col items-end z-50" ref={ref}>
      <img
        src={userAvatar}
        alt="Profile"
        className="ml-6 w-10 avatar h-10 border-2 border-accent rounded-full cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        className={
          "bg-white absolute rounded rounded-tr-none avatar-links overflow-hidden mr-8 sm:mr-16 md:mr-20 pin-r shadow-md " +
          (isOpen ? "block" : "hidden")
        }
      >
        <Link
          to="/dashboard/account-details/"
          className={dropDownItemClasses}
          onClick={() => setIsOpen(false)}
        >
          Account Details
        </Link>
        <button className={dropDownItemClasses} onClick={logout}>
          Log Out
        </button>
      </div>
    </div>
  );
};

export default memo(Avatar);
