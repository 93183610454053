import React, { Component } from "react";
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import "./App.css";
import * as Sentry from "@sentry/browser";
import { Elements } from "@stripe/react-stripe-js";
import withStore from "./helpers/withStore";
import UserContainer from "./components/UserContainer/UserContainer";
import Setup from "./components/Setup/Setup";
import Dashboard from "./components/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Loader from "./components/Loader/Loader";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import { loadStripe } from "@stripe/stripe-js";
import stripeApiKey from "./helpers/stripeApiKey";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://83529706c75d4c339d1cc22deb0c43d5@sentry.io/1451353"
  });
}

const stripePromise = loadStripe(stripeApiKey);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUser: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  redirectAppropriately() {
    const user = this.props.user.state;

    if (user.onboarding < 3) {
      return <Redirect to="/setup" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  componentDidMount() {
    this.setState({ loadingUser: true });
    this.props.user.updateUser().then(() => {
      this.setState({ loadingUser: false });
    });
  }

  render() {
    if (this.state.loadingUser) {
      return <Loader />;
    }
    return (
      <Elements stripe={stripePromise}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return this.redirectAppropriately();
            }}
          />
          <ProtectedRoute
            path="/setup/"
            component={Setup}
            loading={this.state.loadingUser}
          />
          <ProtectedRoute
            path="/dashboard/"
            component={Dashboard}
            loading={this.state.loadingUser}
          />
          <Route exact path="/login/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Elements>
    );
  }
}

export default withRouter(withStore([UserContainer])(App));
