import React from "react";
import { Route, Redirect } from "react-router-dom";
import withStore from "../../helpers/withStore";
import UserContainer from "../UserContainer/UserContainer";

const ProtectedRoute = ({ user, history, component, loading, ...props }) => {
  if (!user.state.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const Component = component;

  if (loading) {
    return null;
  }

  return <Route {...props} component={Component} />;
};

export default withStore([UserContainer])(ProtectedRoute);
