import React, { lazy, Suspense, useEffect } from "react";
import withStore from "../../helpers/withStore";
import { Route, Switch } from "react-router-dom";
import UserContainer from "../UserContainer/UserContainer";
import join from "../../helpers/joinURL";
import Loader from "../Loader/Loader";

const UserDetails = lazy(() => import("./UserDetails/UserDetails")),
  FirstAPI = lazy(() => import("./FirstAPI/FirstAPI"));

const Setup = ({ user, match, history }) => {
  useEffect(() => {
    switch (user.state.onboarding) {
      case 1:
        history.push(join(match.url, "/user-details/"));
        break;
      case 2:
        history.push(join(match.url, "/first-api/"));
        break;
      default:
        history.push("/dashboard");
        break;
    }
  }, [history, user.state, match.url]);

  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <Route
          exact
          path={`${match.url}/user-details/`}
          component={UserDetails}
        />
        <Route exact path={`${match.url}/first-api/`} component={FirstAPI} />
      </Suspense>
    </Switch>
  );
};

export default withStore([UserContainer])(Setup);
