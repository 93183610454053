import React, { memo } from "react";
import "./BreadCrumbs.css";
import { Link } from "react-router-dom";
import RightChevronIcon from "../../../../assets/Chevron Right.svg";

function generateElements(breadCrumbs) {
  let elements = [];

  breadCrumbs.forEach(({ title, link }, index) => {
    let element;

    if (index === breadCrumbs.length - 1) {
      element = title;
    } else {
      element = (
        <span className="text-greengreytext font-medium" key={index}>
          <Link
            className="text-greengreytext hover:text-white color-transition"
            to={link}
          >
            {title}
          </Link>
          <img
            src={RightChevronIcon}
            alt="Next"
            className="bread-crumbs-next-icon h-6 mx-1"
          />
        </span>
      );
    }

    elements.push(element);
  });

  return elements;
}

const BreadCrumbs = ({ history, breadCrumbs }) => {
  const breadCrumbElements = generateElements([...breadCrumbs], history);

  return (
    <h2 className="text-2xl sm:text-3xl text-white tracking-tight inline-block capitalize">
      {breadCrumbElements}
    </h2>
  );
};

export default memo(BreadCrumbs);
