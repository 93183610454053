import React from "react";
import "./NavBar.css";
import { NavLink } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import Avatar from "./Avatar/Avatar";

const NavBar = ({ userAvatar, breadCrumbs, logout }) => {
  return (
    <div className="pt-12 pb-4">
      <div className="flex justify-between items-center">
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <Avatar userAvatar={userAvatar} logout={logout} />
      </div>
      <hr className="nav-bar-divider w-full my-3 bg-white" />
      <NavLink
        to="/dashboard/apis/"
        activeClassName="text-white"
        className="text-greengreytext font-medium mr-4 hover:text-white color-transition"
      >
        APIs
      </NavLink>
      <NavLink
        to="/dashboard/account-details/"
        activeClassName="text-white"
        className="text-greengreytext font-medium hover:text-white color-transition"
      >
        Account Details
      </NavLink>
    </div>
  );
};

export default NavBar;
