import React, { useState, lazy, Suspense } from "react";
import withStore from "../../helpers/withStore";
import UserContainer from "../UserContainer/UserContainer";
import { Route, Redirect, Switch } from "react-router-dom";
import NavBar from "./NavBar/NavBar";
import join from "../../helpers/joinURL";
import Loader from "../Loader/Loader";

const APIs = lazy(() => import("./APIs/APIs")),
  AccountDetails = lazy(() => import("./AccountDetails/AccountDetails"));

const Dashboard = ({ user, match }) => {
  const [breadCrumbs, setBreadCrumbs] = useState([
    { title: "Dashboard", link: "/dashboard" }
  ]);

  return (
    <>
      <div className="bg-primary pb-32">
        <div className="px-8 sm:px-16 md:px-20">
          <Route
            render={props => (
              <NavBar
                userAvatar={`${user.state.picture}?sz=100`}
                logout={user.logout}
                breadCrumbs={breadCrumbs}
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div className="px-8 sm:px-16 md:px-20 -mt-30">
        <Suspense fallback={<Loader />}>
          <Switch>
            {user.state.onboarding < 3 && <Redirect to="/setup" />}
            <Route
              path={`${match.url}/apis/`}
              render={() => (
                <APIs
                  storages={user.state.storages}
                  setBreadCrumbs={setBreadCrumbs}
                />
              )}
            />
            <Route
              path={`${match.url}/account-details/`}
              render={() => (
                <AccountDetails user={user} setBreadCrumbs={setBreadCrumbs} />
              )}
            />
            {match.isExact ? (
              <Redirect
                exact
                from={match.path}
                to={join(match.path, "/apis/")}
              />
            ) : (
              <Redirect to="/404" />
            )}
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default withStore([UserContainer])(Dashboard);
